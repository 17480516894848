import { useAnimationOnScreen } from '@dreamstack/util'
import { motion } from 'framer-motion'
import type { FunctionComponent } from 'react'
import 'twin.macro'

export const staggerWrapperVariants = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.05,
      delayChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
}

export const staggerElementVariants = {
  visible: { opacity: 1, y: 0, transition: { type: 'linear' } },
  hidden: { opacity: 0, y: 50 },
}

// TODO: Make it work with twin macro / styled components and use in PartnerGrid.tsx
export const AnimateStaggerWrapper: FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const { controls, ref } = useAnimationOnScreen()

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={staggerWrapperVariants}
    >
      {children}
    </motion.div>
  )
}

export const AnimateStaggerElement: FunctionComponent<React.PropsWithChildren<{ style?: any }>> = ({
  children,
  style,
}) => {
  return (
    <motion.div variants={staggerElementVariants} style={style}>
      {children}
    </motion.div>
  )
}
