import {
  AnimateStaggerElement,
  GridWithGaps,
  staggerWrapperVariants,
} from '@dreamstack/feature-components'
import type { BlockPartnerGridFragment } from '@dreamstack/investors-graphql'
import { useAnimationOnScreen } from '@dreamstack/util'
import { motion } from 'framer-motion'
import map from 'lodash/map'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'

const StyledPartnerGrid = tw(
  motion.div
)`col-span-full grid grid-cols-2 gap-x-px-24 gap-y-px-48 md:(grid-cols-4 gap-x-0)`
const StyledPartnerText = tw.p`text-px-13 leading-px-18 mt-px-32 text-center lg:(text-px-16 leading-px-24 mt-px-40)`
const StyledPartnerEntry = styled.div<{ isLast: boolean }>(({ isLast }) => [
  isLast ? tw`` : tw`md:(border-r-2 border-accentroGray-200)`,
  tw`md:p-px-24`,
])

const StyledLink = styled.a<{ hasHref?: boolean }>(({ href }) => [
  href && tw`cursor-pointer`,
])

const PartnerContainer: FunctionComponent<
  React.PropsWithChildren<{ href?: string }>
> = ({ href, children }) => {
  const hasHref = !!href
  if (hasHref)
    return (
      <StyledLink hasHref={hasHref} target={'_blank'} href={href}>
        {children}
      </StyledLink>
    )
  else return <>{children}</>
}

export const PartnerGrid: FunctionComponent<
  React.PropsWithChildren<{
    block: BlockPartnerGridFragment
  }>
> = ({ block }) => {
  const { controls, ref } = useAnimationOnScreen()

  return (
    <>
      <GridWithGaps>
        <StyledPartnerGrid
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={staggerWrapperVariants}
        >
          {map(block.partnersCollection?.items, (partner, index) => {
            const isLastInRow = (index + 1) % 4 === 0
            return (
              <AnimateStaggerElement key={index}>
                <PartnerContainer href={partner?.href ?? '#'}>
                  <StyledPartnerEntry isLast={isLastInRow}>
                    {!!partner?.partnerImage?.url && (
                      <div tw="relative aspect-w-2 aspect-h-1">
                        <Image
                          tw="object-cover"
                          layout="fill"
                          src={partner?.partnerImage?.url}
                          alt={
                            partner?.partnerImage?.description ||
                            'partner image'
                          }
                        />
                      </div>
                    )}
                    <StyledPartnerText>
                      {partner?.partnerText}
                    </StyledPartnerText>
                  </StyledPartnerEntry>
                </PartnerContainer>
              </AnimateStaggerElement>
            )
          })}
        </StyledPartnerGrid>
      </GridWithGaps>
    </>
  )
}
